import { Grid, Link, TextField, Typography } from "@mui/material";
import { Description, Folder, Location, Patient } from "components/oldDesignAssets/icons";
import { format } from "date-fns";
import { requestStore } from "stores/modalManager";
import { store } from "stores/storeManager";
import { getFormattedAddress, isValidDate, roundDateTime } from "utils/globalUtils";
import AutoComplete from "../autoComplete";
import { BlueShield, BluedotDash, Clock } from "../icons";
import Input from "../input";
import InputButton from "../inputButton";
import Tooltip from "../tooltip";
import AddressList from "./addressList";
import CaseList from "./caseList";

export default function MedDeliveryForm({
  onChangeSchedule,
  error,
  onChangePatient,
  showSubScreen,
  setError,
  onClickCreateAvixoUser,
  onClickEditPatient,
}) {
  const { unsavedChanges: request } = requestStore.useState(s => s);

  const { country, alpha2 } = store.useState(s => ({
    country: s.country,
    alpha2: s.country.alpha2,
  }));

  const payer = request.owner || request.user;
  const patient = request.user;
  const caseText = request.case.id ? `C-${request.case.id}` : "(New)";
  const programTitle = request.case.program?.title || "";
  const caseBusinessTag = request.case.business?.tag || "";

  const notesIcon = <Description />;
  const jarvisNote = request.records.find(r => r.type === "jarvisNotes")?.data.jarvisNotes[0].message;
  const { destinationAddress } = request.schedule;

  const onChangeJarvisNote = message => {
    requestStore.update(s => {
      const newRecords = request.records.filter(r => r.type !== "jarvisNotes");
      newRecords.push({ type: "jarvisNotes", data: { jarvisNotes: [{ message }] } });
      s.unsavedChanges.records = newRecords;
      s.unsavedChanges.isDirty.records = true;
    });
  };

  const handleChangeScheduleStart = event => {
    const newSchedule = {
      start: isValidDate(event.target.value) && new Date(event.target.value),
    };
    onChangeSchedule(newSchedule);
  };

  const showAddressList = () => showSubScreen(<AddressList />);

  const onClickAddPatient = () => {
    window.open("/patient/new", "_blank");
  };

  return (
    <>
      <div className="scheduleStart">
        <Grid container className="scheduleUM" alignItems="center" gap={0.5}>
          <span className="icon">
            <Clock />
          </span>

          <Grid item xs={5}>
            <TextField
              type="datetime-local"
              value={
                isValidDate(request.schedule.start)
                  ? format(new Date(request.schedule.start), "yyyy-MM-dd'T'HH:mm")
                  : ""
              }
              onChange={handleChangeScheduleStart}
              inputProps={{
                min: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
                onBlur: () => roundDateTime({ request, onChange: onChangeSchedule }),
                sx: { padding: "0px 10px" },
              }}
              size="small"
              className="scheduleDateTimePickerTextField"
              fullWidth
              error={isValidDate(request.schedule.start) && new Date(request.schedule.start) < new Date()}
              InputProps={{ sx: { minHeight: "unset", height: "30px" } }}
              data-testid="selectStartDateTime"
            />
          </Grid>

          {error.schedule && <Tooltip text="Required Field" />}
        </Grid>
      </div>

      <Grid container px={2} pt={2} alignItems="center" gap={2}>
        <Grid item xs={0.5} container justifyContent="center" alignItems="center">
          <BluedotDash />
        </Grid>

        <Grid item xs>
          <Typography variant="caption">{getFormattedAddress(country?.pickup?.address)}</Typography>
        </Grid>
      </Grid>

      <InputButton
        className="requestedAddress"
        icon={<Location className="redIcon" />}
        placeholder="Add Location"
        data-testid="addLocation"
        text={getFormattedAddress(destinationAddress)}
        showInfo={error.destinationAddress}
        onClick={showAddressList}
        disabled={!payer?._id}
      />

      <hr className="addressSeparator" />

      <div className="patientContainer">
        <AutoComplete
          className="patient"
          icon={<Patient />}
          defaultValue={patient.name}
          disabled={!payer?._id || request.payerDisabled}
          onSelect={i => onChangePatient(payer?.relatives[i])}
          list={payer?.relatives?.map(({ name }) => ({ title: name }))}
          subscreenText="New Patient"
          openOnClick
          onClick={onClickAddPatient}
          onDisabledClick={!request.payerDisabled && (() => setError({ ...error, payer: true }))}
          onDelete={() => {
            requestStore.update(s => {
              s.unsavedChanges.user = { paymentMethods: [], phoneNumbers: [], relatives: [] };
              s.unsavedChanges.case = {};
              s.unsavedChanges.payerIsPatient = false;
            });
          }}
          placeholder="Add patient"
          data-testid="addPatient"
          showInfo={error.patient}
          editInfo={
            patient._id && (
              <div className="inputEdit">
                {patient.platos.length ? (
                  <Link
                    href={`${process.env.REACT_APP_AVIXO_URL}${alpha2.toLowerCase()}/patient/default/view?id=${
                      patient.platos[0].id
                    }`}
                    target="_blank"
                    rel="noreferrer"
                    color="secondary">
                    Open in Avixo
                  </Link>
                ) : (
                  <Link href="#" onClick={onClickCreateAvixoUser({ jarvisRef: patient._id })} color="secondary">
                    Create in Avixo
                  </Link>
                )}
                <Link href="#" onClick={onClickEditPatient} color="secondary">
                  Edit
                </Link>
              </div>
            )
          }
        />
      </div>

      <InputButton
        className="case"
        icon={<Folder />}
        text={request.case.type ? `${request.case.type} ${programTitle} ${caseBusinessTag} ${caseText}` : ""}
        showInfo={error.case || request.business}
        info={
          patient.name &&
          request.business && (
            <div className="coveredTag">
              <BlueShield /> COVERED
            </div>
          )
        }
        placeholder="Select case"
        data-testid="selectCase"
        disabled={request.caseDisabled || !patient._id}
        onClick={() => showSubScreen(<CaseList />)}
      />

      <Input
        className="notes"
        icon={notesIcon}
        noBorder
        value={jarvisNote}
        placeholder="Add notes"
        data-testid="enterJarvisNotes"
        isMultiLine
        counter
        maxLength={1000}
        onChange={onChangeJarvisNote}
      />
    </>
  );
}
