import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import QueueIcon from "@mui/icons-material/Queue";
import {
  Badge,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Button as MUIButton,
  Link as MUILink,
  MenuItem,
  MenuList,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { default as MUITooltip } from "@mui/material/Tooltip";
import clsx from "clsx";
import ServicePaymentStatus from "components/global/ServicePaymentStatus";
import DeliveryServiceIcon from "components/icons/deliveryServiceIcon";
import {
  CheckGrey,
  Description,
  FileAdd,
  Folder,
  Location,
  Patient,
  Pencil,
  Percent,
  Person,
  Provider2,
  Thermometer,
  X,
} from "components/oldDesignAssets/icons";
import { useConfirmationModal } from "contexts/confirmationModalContext";
import { useSnackBar } from "contexts/snackBarContext";
import { addMinutes, differenceInDays, format, formatDistance, roundToNearestMinutes } from "date-fns";
import useUsmsUser from "hooks/useUsmsUser";
import { cloneDeep } from "lodash";
import { Fragment, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { createAvixoUser, getSymptoms } from "services/dispatcherService";
import { createAndSendStripeInvoice } from "services/ubfOldService";
import { getAvailablePaymentMethods } from "utils/getAvailablePaymentMethods";
import LoadingSection from "../../../components/loadingSection";
import {
  acknowledge,
  assignRequest,
  attachInvoiceNo,
  chargeService,
  completeRequest,
  confirmCancellation,
  createDeliveryService,
  dischargeRequest,
  getRequest,
  log,
  progressRequest,
  sendPaymentReminder,
  transitRequest,
  triageRequest,
  visitEndRequest,
} from "../../../services/requestService";
import { requestStore } from "../../../stores/modalManager";
import { store } from "../../../stores/storeManager";
import {
  containsAll,
  getDefaultEndTime,
  getFormattedAddress,
  getInterval,
  getPaymentIcon,
} from "../../../utils/globalUtils";
import Price from "../../global/price";
import { BluedotDash, Building, FlagCheckIcon, FreeDollar, Heartbeat, PatientNotes } from "../../universalModal/icons";
import Notification from "../../universalModal/pages/Notification";
import ConfirmCancellation from "../../universalModal/pages/confirmCancellation";
import Avatar from "../avatar";
import Button from "../button";
import Footer from "../footer";
import Header from "../header";
import MediaUploader from "../mediaUploader";
import OptionButton from "../optionButton";
import StatusTag from "../statusTag";
import Tag from "../tag";
import TagInfo from "../tagInfo";
import Timer from "../timer";
import Toggle from "../toggle";
import Tooltip from "../tooltip";
import UserVerificationID from "../userVerificationID";
import CaseInformation from "./CaseInformation";
import ActivityLog from "./activityLog";
import CreateRequest from "./createRequest";
import DeliveryRequest from "./deliveryRequest";
import EditRequest from "./editRequest";
import EMRField from "./emrField";
import PaymentHistory from "./paymentHistory";
import PaymentMethod from "./paymentMethod";
import ProviderAssignment from "./providerAssignment";
import TaskList from "./taskList";

const deliveryStatusColorMapping = {
  cancelled: "errorRed.500",
  completed: "icon.light",
  discharged: "icon.light",
};

export default function Request() {
  const { open } = useConfirmationModal();
  const { open: openSecondDialog } = useConfirmationModal();
  const { request, baseRequest } = requestStore.useState(s => ({
    request: {
      ...s.request,
      ...s.unsavedChanges,
    },
    baseRequest: s.request,
  }));

  const { currencyToUse, language, country } = store.useState(s => ({
    currencyToUse: s.country.currencySymbol,
    language: s.language,
    country: s.country,
  }));
  const alpha2 = country.alpha2;
  const hideModal = requestStore.useState(s => s.hideModal);

  const snackBar = useSnackBar();

  const [error, setError] = useState({});
  const [chargeRequest, setChargeRequest] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [customAmount, setCustomAmount] = useState({
    amount: null,
    error: null,
    isEnabled: false,
  });

  const { t = () => "" } = useTranslation();
  const serviceStatus = t(`${language}.serviceStatus`);
  const serviceTitle = t(`${language}.serviceTitleShort`);

  const payer = useUsmsUser(request.owner || request.user, alpha2);
  const patient = useUsmsUser(request.user, alpha2);
  const payerIsPatient = payer._id === patient._id;
  const {
    free,
    selectedPaymentMethod: paymentMethod,
    invalidInvoice_PS_v05,
    status: paymentStatus,
    totals,
    paymentServiceMeta,
    payments, // this is the payment list coming from Payment Service
    code,
  } = request.billing;
  const invoiceItems = request.items;
  const addonItems = request.items.filter(item => item.item?.tags && containsAll(item.item.tags, ["addons"]));
  const symptomRecord = request.records.find(r => r.type === "symptoms") || {};
  const clinicalNote = request.records.find(r => r.type === "clinicalNotes")?.data.clinicalNotes[0].message;
  const conditionsRecord = request.user.records?.find(r => r.type === "conditions") || {};
  const allergyData = conditionsRecord?.data?.allergy?.data;
  const latestAllergyNote = allergyData?.[allergyData?.length - 1]?.value;

  const isVideo = request.type === "Video";
  const isClinic = request.type === "Clinic";
  const isBabyVaccinationCase = request.case.type === "Baby Vaccination";
  const isLessThan2Days = request.schedule.start && differenceInDays(new Date(request.schedule.start), new Date()) < 3;
  const hasActiveCard = request.availablePaymentMethods?.some(p => p.type === "card" && p.isActive);
  const isAttachedInvoice = !!baseRequest.plato?.invoiceId;
  const isUnpaidOrPartial = ["UNPAID", "PARTIAL"].includes(paymentStatus);
  const activeStripeInvoice = payments?.find(p => p.remark.includes("Stripe Invoice") && p.status === "initialized");
  const canSendStripeInvoice = !isVideo && isAttachedInvoice && isUnpaidOrPartial && !activeStripeInvoice;

  const validateAcceptedRequest = useCallback(() => {
    const err = {
      provider: request.providers && !request.providers.length,
    };
    setError(err);
    return !err.provider;
  }, [request.providers]);

  const validateVisitEndRequest = useCallback(() => {
    const err = {
      emrInvoiceId: !request.plato?.invoiceId || request.plato.invoiceId?.length < 12,
      paymentMethod: (!free && !paymentMethod) || (paymentMethod?.type === "nets" && !request.billing.note),
    };
    setError(err);

    if (err.emrInvoiceId) {
      snackBar.pop({
        content: "Error: The EHR invoice id needs to be minimum 12 digits",
        alertProps: { severity: "error" },
      });
      return false;
    }

    return !Object.values(err).find(e => e);
  }, [free, paymentMethod, request.billing?.note, request.plato?.invoiceId, snackBar]);

  const updateRequest = useCallback(
    updatedFields => {
      const updatedRequest = { ...request, ...updatedFields };
      requestStore.update(s => {
        for (const fieldName in updatedFields) s.unsavedChanges[fieldName] = updatedFields[fieldName];
      });
      return updatedRequest;
    },
    [request],
  );

  const showSubScreen = subScreen => {
    requestStore.update(s => {
      s.componentsToRender = subScreen;
    });
  };

  const handleAcceptedToAssigned = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await assignRequest(request._id, {
        providers: request.providers.map(p => ({ userRef: p.user._id, owner: p.owner, adhoc: p.adhoc })),
        scheduleStart: request.schedule.start,
        scheduleEnd: request.schedule.end,
      });
      snackBar.pop({
        content: result.data.message,
      });
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [request._id, request.providers, request.schedule.end, request.schedule.start, snackBar]);

  const handleAssignedToInTransit = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await transitRequest(request._id);
      snackBar.pop({
        content: result.data.message,
      });
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [request._id, snackBar]);

  const handleToVisitStart = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await progressRequest(request._id);
      snackBar.pop({
        content: result.data.message,
      });
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [request._id, snackBar]);

  const handleVisitStartToVisitEnd = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await visitEndRequest(request._id);
      snackBar.pop({
        content: result.data.message,
      });
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [request._id, snackBar]);

  const handleVisitEndToCompleted = useCallback(async () => {
    try {
      setIsLoading(true);

      const result = await completeRequest(request?._id, {
        invoiceId: request.plato?.invoiceId,
        pay: chargeRequest,
        customAmount: customAmount.amount ? customAmount.amount : undefined,
      });
      snackBar.pop({
        content: result.data.message,
      });
      requestStore.update(s => {
        s.request = result.data.service;
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          isDirty: {},
        };
      });
      setCustomAmount({ amount: null, error: null, isEnabled: false });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [chargeRequest, customAmount.amount, request?._id, request.plato?.invoiceId, snackBar]);

  const handleVisitEndToCompleteConfirmation = useCallback(async () => {
    openSecondDialog({
      title: "Billing Confirmation",
      description: (
        <Stack spacing={2}>
          <Typography variant="body2" color="text.secondary">
            Invoice Amount:{" "}
            <Price value={totals?.grand} component="span" variant="body2" color="text.secondary" fontWeight={600} />
          </Typography>

          <Typography variant="body2" color="text.secondary">
            Are you sure you want to proceed with charging the user{" "}
            <Price
              value={customAmount.amount}
              component="span"
              variant="body2"
              color="text.secondary"
              fontWeight={600}
            />
            ?
            <br />
            <br />
            Please confirm by selecting 'Proceed' or 'Cancel' below.
          </Typography>
        </Stack>
      ),
      confirmationLabel: "Proceed",
      onConfirm: handleVisitEndToCompleted,
    });
  }, [customAmount.amount, handleVisitEndToCompleted, openSecondDialog, totals?.grand]);

  const handleCompletedToClosed = useCallback(async () => {
    try {
      setIsLoading(true);
      await dischargeRequest(request._id);
      snackBar.pop({
        content: "Request has been discharged successfully",
      });
      requestStore.update(s => {
        s.request.status = "discharged";
      });
      hideModal();
    } catch (e) {
      snackBar.pop({
        content: e.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [hideModal, request._id, snackBar]);

  const handleTriage = useCallback(
    async isOn => {
      try {
        setIsLoading(true);
        const result = await triageRequest(request._id, { confirmStatus: isOn });
        snackBar.pop({
          content: result.data.message,
        });
        requestStore.update(s => {
          s.request = result.data.service;
        });
      } catch (error) {
        snackBar.pop({
          content: error.data?.toString(),
          alertProps: { severity: "error" },
        });
      } finally {
        setIsLoading(false);
      }
    },
    [request._id, snackBar],
  );

  const handleCall = async () => {
    try {
      setIsLoading(true);
      const result = await log(request._id, { logType: "phoneCall" });
      requestStore.update(s => {
        s.request = result.data.service;
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onClickButton = useCallback(async () => {
    switch (request.status) {
      case "accepted":
        if (validateAcceptedRequest()) handleAcceptedToAssigned();
        break;
      case "assigned":
        isClinic ? handleToVisitStart() : handleAssignedToInTransit();
        break;
      case "inTransit":
        handleToVisitStart();
        break;
      case "inProgress":
        handleVisitStartToVisitEnd();
        break;
      case "visitEnd":
        if (validateVisitEndRequest()) {
          if (customAmount.isEnabled) handleVisitEndToCompleteConfirmation();
          else handleVisitEndToCompleted();
        }
        break;
      case "completed":
        handleCompletedToClosed();
        break;
      default:
        return false;
    }
  }, [
    request.status,
    validateAcceptedRequest,
    handleAcceptedToAssigned,
    isClinic,
    handleToVisitStart,
    handleAssignedToInTransit,
    handleVisitStartToVisitEnd,
    validateVisitEndRequest,
    handleCompletedToClosed,
    customAmount.isEnabled,
    handleVisitEndToCompleteConfirmation,
    handleVisitEndToCompleted,
  ]);

  const onClickEditRequest = useCallback(
    async e => {
      e.preventDefault();

      requestStore.update(s => {
        s.unsavedChanges = {
          billing: { ...s.request.billing },
          schedule: { ...s.request.schedule },
          providers: [...s.request.providers],
          availablePaymentMethods: getAvailablePaymentMethods({
            country,
            user: s.request.user,
            owner: s.request.owner,
          }),
          isDirty: {},
        };
        s.componentsToRender = <EditRequest />;
      });
    },
    [country],
  );

  const pullPlatoItems = useCallback(async () => {
    if (!request.plato?.invoiceId) return;

    setIsLoadingItems(true);

    try {
      const { data } = await attachInvoiceNo(request._id, {
        invoiceNo: request.plato.invoiceId,
      });
      requestStore.update(s => {
        s.request.billing = { ...s.request.billing, ...data.service.billing };
        s.request.items = data.service.items;
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoadingItems(false);
    }
  }, [request._id, request.plato?.invoiceId, snackBar]);

  const onConfirmChargeRequest = useCallback(async () => {
    try {
      if (paymentStatus !== "UNPAID") return;
      if (!hasActiveCard || !request.billing?.psId) return;

      setIsLoading(true);

      const req = await chargeService(request._id);
      requestStore.update(s => {
        s.request = req.data.service;
      });

      snackBar.pop({
        content: "Request has been charged successfully",
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [hasActiveCard, paymentStatus, request._id, request.billing?.psId, snackBar]);

  const handleChargeRequest = useCallback(() => {
    open({
      title: "Billing Confirmation",
      description: (
        <Typography variant="body2" color="text.secondary">
          You are about to charge the user{" "}
          <Price value={totals?.grand} component="span" variant="body2" color="text.secondary" fontWeight={600} />
          .
          <br />
          <br />
          Do you wish to proceed?
        </Typography>
      ),
      confirmationLabel: "Proceed",
      onConfirm: onConfirmChargeRequest,
    });
  }, [onConfirmChargeRequest, open, totals?.grand]);

  const onClickPaymentReminder = useCallback(async () => {
    try {
      if (!isUnpaidOrPartial) return;

      setIsLoading(true);

      await sendPaymentReminder(request._id);

      snackBar.pop({
        content: "Payment reminder has been sent successfully",
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [isUnpaidOrPartial, request._id, snackBar]);

  const providerList = request.providers.map(p => (
    <div className="providerInfoItem" key={p.user._id}>
      <Avatar userData={p.user} />
      <div className="providerDetail">
        <div className="name">
          <div className="fullName">{p.user.name}</div>
          {p.owner ? <div className="primaryInfo">Primary</div> : ""}
        </div>
        <div className="type">{p.user.specialty}</div>
      </div>
      <div className="seenStatus">
        {p.seen?.status ? (
          <CheckRoundedIcon sx={{ fontSize: "16px", stroke: "#144FE7", strokeWidth: 2 }} />
        ) : (
          <CheckGrey />
        )}
      </div>
    </div>
  ));

  const destinationAddress = request.type === "Delivery" ? request.schedule.destinationAddress?.line1 : "";
  const destinationAddressDetails = { ...request.schedule.destinationAddress, line1: "" };
  const addressDetails = request.schedule.requestedAddress;

  const onClickNetsReceiptNumber = useCallback(() => {
    showSubScreen(<PaymentMethod />);
  }, []);

  const userBilling = () => {
    const paymentSymbol = () => {
      if (free || paymentMethod?.origin === "business") return null;
      else if (paymentMethod) return getPaymentIcon(paymentMethod);
      else return <Tooltip />;
    };

    const captionText = () => {
      if (free)
        return (
          <div className="freeCaption">
            <FreeDollar />
            <div className="content">
              <span className="main">No Charge</span>
              <span className="action">Do not collect</span>
            </div>
          </div>
        );
      else if (paymentMethod?.origin === "business")
        return (
          <div className="corporateCaption">
            <Building />
            <div className="content">
              <span className="main">
                <Tag>{paymentMethod.name}</Tag>
              </span>
              <span className="action">Do not collect</span>
            </div>
          </div>
        );
      else if (paymentMethod?.type === "other")
        return (
          <>
            {paymentMethod.name} <span className="caption">{request.billing.note}</span>
          </>
        );
      else if (paymentMethod)
        return (
          <>
            {paymentMethod.name || paymentMethod.data.name}

            <span className="caption">{paymentMethod.caption}</span>

            {paymentMethod.type === "nets" &&
              ["visitEnd", "completed", "discharged", "cancelled"].includes(request.status) &&
              (request.status === "visitEnd" ? (
                <Typography
                  onClick={onClickNetsReceiptNumber}
                  fontSize={12}
                  color="#126EF8"
                  sx={{ textDecorationLine: "underline", cursor: "pointer" }}>
                  {request.billing.note || "Input Receipt No."}
                </Typography>
              ) : (
                <Typography fontSize={12} color="black">
                  {request.billing.note}
                </Typography>
              ))}
          </>
        );
      else return "Payment method missing or invalid";
    };

    return (
      <div className="userBilling">
        <span className={`accountNumber ${!paymentMethod && !free ? "invalid" : ""} ${free ? "free" : ""}`}>
          {paymentSymbol()}
          <Grid>{captionText()}</Grid>
        </span>
        {error.paymentMethod && <Tooltip id="tooltip" text="Required field" />}
        {request.business && (
          <Grid container alignItems="center">
            <Building />
            <Typography fontSize={12} fontWeight={500} color="#6c6c6c">
              {request.business.tag}
            </Typography>
          </Grid>
        )}
      </div>
    );
  };

  const onToggleCharge = useCallback(() => {
    setChargeRequest(!chargeRequest);
  }, [chargeRequest]);

  const handleClickNotification = useCallback(() => {
    requestStore.update(s => {
      s.componentsToRender = <Notification />;
    });
  }, []);

  const onClickConfirmCancellation = useCallback(() => {
    requestStore.update(s => {
      s.componentsToRender = <ConfirmCancellation />;
    });
  }, []);

  const onClickRebookCancellation = async () => {
    try {
      setIsLoading(true);

      await confirmCancellation(request._id, {
        confirmStatus: true,
        contacted: true,
        action: "Rebooked",
      });

      const newDraft = await populateNewRequest(request);
      newDraft.isRebook = true;

      requestStore.update(s => {
        s.unsavedChanges = newDraft;
        s.request = null;
        s.config = { showProviderSection: false };
        s.componentsToRender = <CreateRequest />;
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    }
  };

  const onClickDuplicateRequest = async () => {
    try {
      setIsLoading(true);

      const newRequest = await populateNewRequest(request);
      newRequest.duplicatedFromRef = request._id;

      if (["Provider App", "Patient App", "WavPay"].includes(newRequest.origin)) newRequest.origin = undefined;

      requestStore.update(s => {
        s.unsavedChanges = newRequest;
        s.request = null;
        s.config = { showProviderSection: false };
        s.componentsToRender = <CreateRequest />;
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    }
  };

  const onClickCompleteAndNewDelivery = useCallback(async () => {
    try {
      if (validateVisitEndRequest()) {
        setIsLoading(true);

        await createDeliveryService({
          parentRef: request._id,
          specialty: "Rider",
        });

        await handleVisitEndToCompleted();
      }
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [handleVisitEndToCompleted, request._id, snackBar, validateVisitEndRequest]);

  const handleCompleteWithoutService = useCallback(async () => {
    try {
      await onClickButton();
    } catch (error) {
      snackBar.pop({
        content: error.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [onClickButton, snackBar]);

  const onClickDelivery = useCallback(async () => {
    try {
      setIsLoading(true);

      const result = await createDeliveryService({
        parentRef: request._id,
        specialty: "Rider",
      });

      requestStore.update(s => {
        s.request.riderDeliveries.unshift(result.data.service);
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [request._id, snackBar]);

  const onClickCompleteWithoutDelivery = useCallback(() => {
    if (customAmount.isEnabled && !customAmount.amount) {
      setCustomAmount(prev => ({ ...prev, error: "Custom amount field cannot be empty" }));
      return;
    }

    open({
      title: "Complete Without Delivery Service",
      description: "Would you like to move this card to Completed without a delivery service?",
      onConfirm: handleCompleteWithoutService,
    });
  }, [customAmount.amount, customAmount.isEnabled, handleCompleteWithoutService, open]);

  const onClickEditUser = () => {
    window.open(`/patient/edit/${payer._id}`, "_blank", "noreferrer");
  };

  const onClickEditPatient = () => {
    const newRelativeURL = new URL(`/patient/edit/${patient._id}`, window.location.origin);

    if (!payerIsPatient) {
      newRelativeURL.searchParams.set(
        "relativeOf",
        JSON.stringify({ _id: request.owner?._id, name: request.owner?.name }),
      );
    }

    window.open(newRelativeURL.toString(), "_blank", "noreferrer");
  };

  const showProviderAssignment = useCallback(() => showSubScreen(<ProviderAssignment origin="request" />), []);

  const handleChangeInvoice = useCallback(
    invoiceId => updateRequest({ plato: { ...request.plato, invoiceId } }),
    [request.plato, updateRequest],
  );

  const onClickSendStripeInvoice = async () => {
    if (!canSendStripeInvoice) return;

    open({
      title: "Send Stripe Invoice",
      description: (
        <Typography variant="body2" color="text.secondary">
          You will send a Stripe Invoice to the registered email of the main user.
          <br />
          <br />
          Please click Confirm if you would like to proceed.
        </Typography>
      ),
      onConfirm: onConfirmSendStripeInvoice,
    });
  };

  const onConfirmSendStripeInvoice = async () => {
    if (!canSendStripeInvoice) {
      snackBar.pop({ content: "Invalid action, please revalidate", alertProps: { severity: "error" } });
      return;
    }

    try {
      await createAndSendStripeInvoice({ serviceRef: request._id });

      snackBar.pop({ content: "The Stripe Invoice has been generated and sent", alertProps: { severity: "success" } });
    } catch (error) {
      snackBar.pop({ content: error.toString(), alertProps: { severity: "error" } });
    }
  };

  const showLog = useCallback(() => showSubScreen(<ActivityLog />), []);
  const showTaskList = useCallback(() => showSubScreen(<TaskList />), []);

  const onClickCreateAvixoUser = async e => {
    e.preventDefault();

    try {
      const { data } = await createAvixoUser(patient._id, alpha2);

      requestStore.update(s => {
        if (payer._id === patient._id) s.request.owner = { ...data.user };
        s.request.user = { ...data.user };
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    }
  };

  const handleClickedDeliveryService = useCallback(
    id => async () => {
      setIsLoading(true);
      try {
        const response = await getRequest(id);
        requestStore.update(s => {
          s.request = response.data.service;
          s.unsavedChanges = {
            billing: { ...s.request.billing },
            schedule: { ...s.request.schedule },
            providers: [...s.request.providers],
            isDirty: {},
          };
        });
        setIsLoading(false);
      } catch (error) {
        snackBar.pop({
          content: error.data?.toString(),
          alertProps: { severity: "error" },
        });
      }
    },
    [snackBar],
  );

  const onConfirmAppointment = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await acknowledge(request._id);
      requestStore.update(s => {
        s.request = result.data.service;
      });
    } catch (error) {
      snackBar.pop({
        content: error.data?.toString(),
        alertProps: { severity: "error" },
      });
    } finally {
      setIsLoading(false);
    }
  }, [request._id, snackBar]);

  const onToggleCustomAmount = useCallback(() => {
    setCustomAmount(prev => ({ ...prev, isEnabled: !prev.isEnabled }));
  }, []);

  const onChangeCustomAmount = useCallback(e => {
    let amount = e.target.value;
    if (amount < 0) amount = 0;

    setCustomAmount(prev => ({ ...prev, amount, error: null }));
  }, []);

  const onClickCopyAppointmentDetails = useCallback(() => {
    const phoneNumber = payer?.phoneNumbers?.length
      ? `${payer.phoneNumbers[0].ext}${payer.phoneNumbers[0].number}`
      : "-";
    navigator.clipboard.writeText(
      `Jarvis: S${request.id}
      PT Name: ${patient.name}
      Appt: ${request.schedule.start ? format(new Date(request.schedule.start), "EEEE, dd MMM  · HH:mm") : "ASAP"}
      User Phone: ${phoneNumber}
      User Email: ${payer?.email.address}`.replace(/ {2,}/g, ""),
    );
    snackBar.pop({ content: "Copied to clipboard" });
  }, [patient.name, payer?.email.address, payer?.phoneNumbers, request.id, request.schedule.start, snackBar]);

  if (isLoading || !request) return <LoadingSection className="modalContent">Loading request data</LoadingSection>;

  if (request.type === "Delivery" && request.specialty === "Rider") return <DeliveryRequest />;

  return (
    <div className="modalContent requestUM">
      <Header>
        <div className="title" style={{ maxWidth: "240px" }}>
          <Typography role="heading" fontSize={18} fontFamily="PlutoSans, Helvetica" noWrap>
            {serviceTitle[request.type]}
          </Typography>
          <span className="subtitle" data-testid="serviceID" style={{ width: "fit-content" }}>{`S${request.id}`}</span>
          <Typography variant="caption" color="purple.500" whiteSpace="nowrap" width="fit-content" ml={1} mt={0.25}>
            {request.origin}
          </Typography>
        </div>
        <StatusTag className={`${request.status}`} statusName={`${request.status}`}>
          {request.isDraft && "Draft "}
          {serviceStatus[request.status]}
        </StatusTag>
        <MUITooltip title="Copy appointment details" followCursor placement="top">
          <IconButton
            disableRipple
            size="small"
            edge="end"
            aria-label="copy appointment details"
            onClick={onClickCopyAppointmentDetails}>
            <LibraryBooksIcon sx={{ color: "#979797" }} />
          </IconButton>
        </MUITooltip>
        {request.status !== "cancelled" && (
          <MUITooltip title="Duplicate request" followCursor placement="top">
            <IconButton
              disableRipple
              size="small"
              edge="end"
              aria-label="duplicate-request"
              onClick={onClickDuplicateRequest}>
              <QueueIcon sx={{ color: "#979797" }} />
            </IconButton>
          </MUITooltip>
        )}
        {!["cancelled", "discharged"].includes(request.status) && (
          <IconButton disableRipple size="small" edge="end" aria-label="edit-request" onClick={onClickEditRequest}>
            <Pencil sx={{ color: "#979797" }} />
          </IconButton>
        )}
        <X className="close" onClick={hideModal} />
      </Header>

      <span className="specialty">{request.specialty}</span>

      <div className="content">
        <div className="scheduleSection">
          <div className="scheduleUM">
            {request.schedule.start ? format(new Date(request.schedule.start), "EEEE, dd MMM  · HH:mm") : "ASAP"}
          </div>
          <div className="addressUM">{getFormattedAddress(addressDetails)}</div>
        </div>

        {request.riderDeliveries?.length > 0 && (
          <Grid container direction="column" border={1} borderColor="#eeeeee" my={2} data-testid="childServices">
            {request.riderDeliveries.map((rd, idx) => (
              <Grid item xs container key={rd.id} pl={2} wrap="nowrap">
                <Grid
                  container
                  gap={1}
                  py={2}
                  borderBottom={request.riderDeliveries.length - 1 === idx ? undefined : 1}
                  borderColor="#eeeeee">
                  <Grid item xs container gap={1}>
                    <Grid item>
                      <DeliveryServiceIcon />
                    </Grid>
                    <Grid item>
                      <Grid container direction="column">
                        <Grid item>
                          <Typography
                            fontSize={12}
                            fontWeight={600}
                            color="wildTide.500"
                            style={{ cursor: "pointer" }}
                            data-testid={`childService-${rd.status}`}
                            onClick={handleClickedDeliveryService(rd._id)}>
                            Medicine Delivery - S{rd.id}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography fontSize={12} variant="body1">
                            {format(new Date(rd.schedule?.start), "EEEE d MMM · HH:mm")}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs container justifyContent="flex-end" alignSelf="center" pr={2}>
                    <Grid
                      borderRadius="50%"
                      height={10}
                      width={10}
                      ml={1}
                      sx={{
                        position: "relative",
                        backgroundColor: deliveryStatusColorMapping[rd.status] || "successGreen.500",
                      }}>
                      <Box
                        sx={{
                          height: "100%",
                          borderRadius: "50%",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        )}

        <div className="provider">
          <div className="providerGroup">
            <Provider2 />
            {request.status === "accepted" && !request.providers.length ? (
              <>
                <Button text="Assign provider" type="button" className="primary" onClick={showProviderAssignment} />
                {isVideo && !request.schedule.start && <Timer startTime={request.queuedAt} />}
              </>
            ) : (
              <div className="providerInfo">{providerList}</div>
            )}
          </div>
          {error.provider && <Tooltip id="tooltip" text="Required field" />}
        </div>

        <div className="userSection">
          <div className="user">
            <Person />

            <div className="userDetails">
              <div className="clickable" onClick={onClickEditUser}>
                <UserVerificationID user={payer} />
              </div>

              {payer.phoneNumbers?.length && payer.phoneNumbers[0] ? (
                <a href={`tel:${payer.phoneNumbers[0].ext}${payer.phoneNumbers[0].number}`} onClick={handleCall}>
                  {payer.phoneNumbers[0].ext} {payer.phoneNumbers[0].number}
                </a>
              ) : (
                <span>N/A</span>
              )}

              {payer.email.address ? (
                <span className="light" href={`mailto:${payer.email.address}`}>
                  {payer.email.address}
                </span>
              ) : (
                <span>N/A</span>
              )}

              {isBabyVaccinationCase &&
                isLessThan2Days &&
                (!request.acknowledgement?.patient?.at || request.acknowledgement?.patient?.confirmReschedule) && (
                  <MUIButton
                    variant="text"
                    sx={{ px: 0, py: 0.5, minHeight: 0, fontSize: "12px" }}
                    onClick={onConfirmAppointment}>
                    Confirm Appointment
                  </MUIButton>
                )}
            </div>

            <CheckRoundedIcon
              sx={{
                fontSize: "16px",
                stroke:
                  !!request.acknowledgement?.patient?.at &&
                  request.acknowledgement?.patient?.confirmReschedule === false
                    ? "#00C3B5"
                    : "#c3d8e5",
                strokeWidth: 2,
              }}
            />
          </div>

          {userBilling()}
        </div>

        <hr className="addressSeparator" />

        <div className="patientSection">
          <div className="patient">
            <Patient />
            <div className="clickable" onClick={onClickEditPatient}>
              <UserVerificationID user={patient} />
            </div>
          </div>

          {patient.platos?.length ? (
            <a
              target="_blank"
              href={`${process.env.REACT_APP_AVIXO_URL}${alpha2.toLowerCase()}/patient/default/view?id=${
                patient.platos[0]?.id
              }`}
              style={{ color: "blue", paddingTop: "5px" }}
              rel="noreferrer">
              Open in Avixo
            </a>
          ) : (
            <a href="/" onClick={onClickCreateAvixoUser} style={{ color: "blue" }}>
              Create in Avixo
            </a>
          )}
        </div>
        {request.type === "Delivery" && destinationAddress && (
          <div>
            <div className="requestedAddress">
              <BluedotDash />
              <div>
                {getFormattedAddress(addressDetails)}
                {addressDetails?.subtitle && <span className="light">{addressDetails?.subtitle}</span>}
              </div>
            </div>
            <div className="destinationAddress">
              <Location />
              <div>
                {destinationAddress} {getFormattedAddress(destinationAddressDetails)}
                {destinationAddressDetails?.subtitle && (
                  <span className="light">{destinationAddressDetails?.subtitle}</span>
                )}
              </div>
            </div>
          </div>
        )}

        <Grid container px={2} alignItems="center" gap={2}>
          <Folder />

          <Grid item xs>
            <CaseInformation service={request} />
          </Grid>

          {["accepted", "assigned"].includes(request.status) && (
            <Grid item pt={0.5} alignSelf="start">
              <Toggle
                onChange={handleTriage}
                className="triageToggle"
                text="Triage"
                disabled={isLoading}
                isOn={request.confirmed}
              />
            </Grid>
          )}
        </Grid>

        {request.case.metadata?.babyVaccination?.milestone && (
          <Stack mx={2} direction="row" alignItems="center" spacing="20px" mb={1}>
            <FlagCheckIcon />

            <Typography variant="body2">
              Appointment Milestone: {request.case.metadata.babyVaccination.milestone} months
            </Typography>
          </Stack>
        )}

        <div className="symptomsUM">
          <Thermometer />
          {(symptomRecord.data?.symptoms && symptomRecord.data.symptoms.join(", ")) || "N/A"}
        </div>
        {request.type === "Delivery" && (
          <div className="notes">
            <Heartbeat />
            <div>
              <span className="preWrap">{symptomRecord.data?.patientCondition || "N/A"}</span>
            </div>
          </div>
        )}
        <div className="notes addonItems">
          <FileAdd />
          <div className="text">
            {addonItems.length
              ? addonItems.map(item => (
                  <TagInfo
                    key={item.item._id}
                    title={[item.item?.tags?.includes("app") && "(In App)", item.item.title].filter(Boolean).join(" ")}
                    subtitle={
                      <>
                        {item.item.description}{" "}
                        <Price value={item.price?.base} fontSize="inherit" ml={1} color="secondary" />
                      </>
                    }
                  />
                ))
              : "N/A"}
          </div>
        </div>
        <div className="notes">
          <PatientNotes />
          <Stack gap={1}>
            <span className="preWrap">{symptomRecord.data?.notes || "N/A"}</span>
            <span className="preWrap">Drug Allergy: {latestAllergyNote || "N/A"}</span>
          </Stack>
        </div>
        <div className="notes">
          <Description />
          <span className="preWrap">{clinicalNote || "N/A"}</span>
        </div>

        {["accepted", "assigned", "inTransit", "inProgress", "visitEnd", "completed"].includes(request.status) && (
          <MediaUploader />
        )}

        <Grid container px={2} alignItems="center" justifyContent="space-between">
          {!["completed", "discharged"].includes(request.status) && (
            <EMRField
              onChangeInput={handleChangeInvoice}
              value={request.plato?.invoiceId}
              isAttachedInvoice={isAttachedInvoice}
              isDisabled={request.isDraft}
              isLoading={isLoadingItems}
              onClickButton={pullPlatoItems}
              errorMsg={error.emrInvoiceId}
            />
          )}

          {["completed", "discharged"].includes(request.status) && paymentServiceMeta?.invoiceUrl && (
            <Grid>
              Avixo Invoice No:{" "}
              <MUILink href={paymentServiceMeta.invoiceUrl} color="secondary" target="_blank" rel="noreferrer">
                {request.plato?.invoiceId}
              </MUILink>
            </Grid>
          )}

          {alpha2 === "SG" && !isVideo && ["visitEnd", "completed"].includes(request.status) && isAttachedInvoice && (
            <Typography
              onClick={onClickSendStripeInvoice}
              color={canSendStripeInvoice ? "wildTide.500" : "text.secondary"}
              fontSize={12}
              fontWeight={600}
              sx={{ cursor: canSendStripeInvoice ? "pointer" : "default" }}>
              Send Stripe Invoice
            </Typography>
          )}
        </Grid>

        {invalidInvoice_PS_v05 && (
          <Grid
            container
            alignItems="center"
            mt={1.5}
            px={2}
            py={1}
            gap={1.5}
            bgcolor="errorRed.50"
            color="errorRed.500">
            <ErrorOutlineIcon />
            Avixo invoice has been deleted or cannot be retrieved.
          </Grid>
        )}

        {code?.id && (
          <Grid className="notes" sx={{ svg: { path: { fill: "#979797" } } }}>
            <Percent />
            <Box>Discount: {code.id}</Box>
          </Grid>
        )}

        {invoiceItems && (
          <div className={clsx("feesTable", { edit: invalidInvoice_PS_v05 })}>
            <table>
              <thead>
                <tr>
                  <th>Service Item</th>
                  <th>Qty</th>
                  <th>Base {currencyToUse}</th>
                  <th>Disc</th>
                  <th>Price {currencyToUse}</th>
                </tr>
              </thead>

              <tbody>
                {invoiceItems.map(item => (
                  <Fragment key={`item.${item._id || item.id}`}>
                    <tr>
                      <td>
                        {[item.item?.tags?.includes("app") && "(In App)", item.description].filter(Boolean).join(" ")}
                        {!!item.price?.discount?.type && (
                          <Typography component="p" variant="caption" color="successGreen.500">
                            Discount {item.price.discount.value.toLocaleString()}
                            {item.price.discount.type === "percentage" && "%"}
                          </Typography>
                        )}
                      </td>
                      <td>x{item.quantity}</td>
                      <td>{isNaN(item.price?.base) ? "N/A" : Number(item.price.base).toLocaleString()}</td>
                      <td>{item.price?.discountedAmount ? item.price?.discountedAmount?.toLocaleString() : "-"}</td>
                      <td>{isNaN(item.price?.total) ? "N/A" : Number(item.price.total).toLocaleString()}</td>
                    </tr>
                  </Fragment>
                ))}

                {request.billing.code?.itemTags?.length === 0 && !request.plato?.invoiceId && (
                  <tr>
                    <td className="discountItem">
                      {request.billing.code.type === "percentage" ? (
                        `${Number(request.billing.code.value).toLocaleString()}% Grand Total Discount`
                      ) : (
                        <Price value={request.billing.code.value} variant="caption" postfix=" Fixed Discount" />
                      )}
                    </td>
                    <td>x1</td>
                    <td>-</td>
                    <td>{`${Number(request.billing.code.value).toLocaleString()}${
                      request.billing.code.type === "percentage" ? "%" : ""
                    }`}</td>
                    <td>
                      -
                      {Number(
                        request.billing.code.type === "percentage"
                          ? (request.billing.code.value / (100 - request.billing.code.value)) * totals.grand
                          : request.billing.code.value,
                      ).toLocaleString()}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <Divider />

            {totals.amountDue !== undefined && (
              <Grid container justifyContent="space-between" mt={1.5}>
                <Typography component="span" variant="caption" fontWeight={600}>
                  Subtotal
                </Typography>

                <Typography component="span" variant="caption" fontWeight={600}>
                  {Number(totals.subtotal).toLocaleString()}
                </Typography>
              </Grid>
            )}

            {!!totals.taxes && (
              <Grid container justifyContent="space-between" mt={0.25}>
                <Typography component="span" variant="caption" color="text.secondary">
                  GST
                </Typography>

                <Typography component="span" variant="caption" color="text.secondary">
                  {Number(totals.taxes || 0).toLocaleString()}
                </Typography>
              </Grid>
            )}

            {!!totals.discount && (
              <Grid container justifyContent="space-between" mt={0.25}>
                <Typography component="span" variant="caption" color="successGreen.500">
                  Discount
                </Typography>

                <Typography component="span" variant="caption" color="successGreen.500">
                  {Number(totals.discount).toLocaleString()}
                </Typography>
              </Grid>
            )}

            {totals.amountDue !== undefined && <Divider sx={{ mt: 1.5 }} />}

            <Grid container justifyContent="space-between" mt={1.5}>
              <Typography component="span" variant="caption" fontWeight={600}>
                Total
              </Typography>

              <Typography component="span" variant="caption" fontWeight={600}>
                {Number(totals.grand).toLocaleString()}
              </Typography>
            </Grid>

            {isAttachedInvoice && request.status === "visitEnd" && paymentStatus !== "PAID" && (
              <Grid container justifyContent="space-between" mt={1.5}>
                <Grid item xs={6}>
                  <MUITooltip
                    title="Adjust the amount to collect now. This does not change the total invoice amount."
                    placement="top">
                    <FormControlLabel
                      label="Charge Custom Amount"
                      labelPlacement="start"
                      sx={{ margin: 0 }}
                      control={
                        <Switch
                          checked={customAmount.isEnabled}
                          onChange={onToggleCustomAmount}
                          sx={{
                            ".MuiSwitch-thumb": { backgroundColor: "#FFFFFF" },
                            "& .MuiSwitch-switchBase": {
                              "&.Mui-checked": {
                                ".MuiSwitch-thumb": { backgroundColor: "#00C3B5" },
                                "& + .MuiSwitch-track": { backgroundColor: "#C9EBEA" },
                              },
                            },
                          }}
                        />
                      }
                      componentsProps={{ typography: { component: "span", variant: "caption", fontWeight: 600 } }}
                    />
                  </MUITooltip>
                </Grid>

                <Grid item xs={6}>
                  {customAmount.isEnabled && (
                    <Grid container textAlign="right" flexDirection="column" alignItems="flex-end">
                      <TextField
                        type="number"
                        variant="outlined"
                        size="small"
                        required
                        inputProps={{
                          style: {
                            textAlign: "right",
                          },
                        }}
                        InputProps={
                          customAmount.amount > totals.grand && {
                            sx: { border: "1px solid", borderColor: "warning.main" },
                          }
                        }
                        sx={{ width: "100px" }}
                        value={customAmount.amount}
                        onChange={onChangeCustomAmount}
                        placeholder={totals.grand}
                        error={!!customAmount.error}
                      />
                      {customAmount.amount > totals.grand && (
                        <Typography variant="caption" color="warning.main">
                          The charging amount entered is higher than the invoice amount
                        </Typography>
                      )}
                      {customAmount.error && (
                        <Typography variant="caption" color="error">
                          {customAmount.error}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}

            {payments?.map(p => (
              <PaymentHistory key={p.id} payment={p} />
            ))}

            {!!totals.amountDue && totals.amountDue !== 0 && paymentStatus !== "UNPAID" && (
              <Grid container justifyContent="space-between" mt={0.5}>
                <Typography component="span" variant="caption" color="warning.main">
                  Amount {totals.amountDue > 0 ? "outstanding" : "overpaid"}
                </Typography>

                <Typography component="span" variant="caption" color="warning.main">
                  {Math.abs(Number(totals.amountDue)).toLocaleString()}
                </Typography>
              </Grid>
            )}
            <Grid height="16px" />
          </div>
        )}
      </div>

      <Footer>
        <hr className="footerLine" />
        <Grid p={2}>
          {request.status === "cancelled" && (
            <div className="caption">
              Canceled by: <span className="redText">{request.cancelled.by?.name}</span>
              <br />
              Reason:
              <span className="redText">{` ${request.cancelled.reason} (${formatDistance(
                new Date(request.createdAt),
                new Date(request.cancelled.date),
              )} after creation)`}</span>
            </div>
          )}

          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs container wrap="nowrap">
              <MUITooltip title="Activity Log" followCursor placement="top">
                <IconButton onClick={showLog} edge="end" size="small">
                  <HistoryOutlinedIcon htmlColor="#979797" sx={{ "&:hover": { color: "#00c3b5" } }} />
                </IconButton>
              </MUITooltip>

              {request.status !== "cancelled" && !["completed", "discharged"].includes(request.status) && (
                <MUITooltip title="Notifications" followCursor placement="top">
                  <IconButton onClick={handleClickNotification} edge="end" size="small">
                    <NotificationsNoneOutlinedIcon htmlColor="#979797" sx={{ "&:hover": { color: "#00c3b5" } }} />
                  </IconButton>
                </MUITooltip>
              )}

              <MUITooltip title="Task Manager" followCursor placement="top">
                <IconButton onClick={showTaskList} edge="end" size="small">
                  <Badge badgeContent={request.taskCount} color="error" overlap="circular">
                    <ListAltOutlinedIcon htmlColor="#979797" sx={{ "&:hover": { color: "#00c3b5" } }} />
                  </Badge>
                </IconButton>
              </MUITooltip>
            </Grid>

            <Grid item xs="auto" container className="rightActions" alignItems="center" gap={2}>
              {request.status === "visitEnd" && paymentMethod && paymentMethod.type === "card" && (
                <FormControlLabel
                  label="Charge User"
                  componentsProps={{ typography: { variant: "caption", color: "#979797" } }}
                  control={<Checkbox color="secondary" checked={chargeRequest} onChange={onToggleCharge} />}
                  sx={{ mr: 0 }}
                />
              )}

              {request.plato?.invoiceId && <ServicePaymentStatus status={paymentStatus} />}

              {!request.isDraft && (
                <ActionButton
                  request={request}
                  isLoading={isLoading}
                  chargeRequest={chargeRequest}
                  handleChargeRequest={handleChargeRequest}
                  handleClickConfirmCancellation={onClickConfirmCancellation}
                  handleClickRebookCancellation={onClickRebookCancellation}
                  handleClickCompleteAndNewDelivery={onClickCompleteAndNewDelivery}
                  handleClickCompleteWithoutDelivery={onClickCompleteWithoutDelivery}
                  handleClickDelivery={onClickDelivery}
                  handleClickButton={onClickButton}
                  handleClickPaymentReminder={onClickPaymentReminder}
                  customAmount={customAmount}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Footer>
    </div>
  );
}

const ActionButton = ({
  request,
  isLoading,
  chargeRequest,
  handleChargeRequest,
  handleClickConfirmCancellation,
  handleClickRebookCancellation,
  handleClickCompleteAndNewDelivery,
  handleClickCompleteWithoutDelivery,
  handleClickDelivery,
  handleClickButton,
  handleClickPaymentReminder,
  customAmount,
}) => {
  const {
    status,
    providers,
    type,
    billing: { free, selectedPaymentMethod: paymentMethod, status: paymentStatus, totals },
  } = request;
  const isClinic = type === "Clinic";
  const hasActiveDelivery = request?.riderDeliveries?.some(
    rd => !["cancelled", "completed", "discharged"].includes(rd.status),
  );
  const hasActiveCard = request.availablePaymentMethods?.some(p => p.type === "card" && p.isActive);

  const buttonText = {
    accepted: "Set Assigned",
    assigned: isClinic ? "Start Visit" : "Set In Transit",
    inTransit: "Start Visit",
    inProgress: "End Visit",
    visitEnd: "Complete",
    completed: "Close Request",
    discharged: "Closed",
  };

  if (status === "visitEnd") {
    let completeButtonText = "Charge User (Complete)";
    if (customAmount.isEnabled && customAmount.amount < totals.amountDue) completeButtonText = "Charge User (Partial)";

    return (
      <OptionButton>
        <MenuList id="option-button-menu" autoFocusItem>
          <MenuItem
            role="button"
            onClick={handleClickCompleteWithoutDelivery}
            sx={{
              fontSize: 12,
            }}>
            {completeButtonText}
          </MenuItem>
          <MenuItem
            role="button"
            disabled={hasActiveDelivery}
            onClick={handleClickCompleteAndNewDelivery}
            sx={{
              fontSize: 12,
            }}>
            Complete and Create Delivery Service
          </MenuItem>
          <MenuItem
            role="button"
            disabled={hasActiveDelivery}
            onClick={handleClickDelivery}
            sx={{
              fontSize: 12,
            }}>
            Create Delivery Service only
          </MenuItem>
        </MenuList>
      </OptionButton>
    );
  }
  if (status === "completed")
    return (
      <OptionButton disabled={isLoading}>
        <MenuList id="option-button-menu" autoFocusItem>
          <MenuItem
            role="button"
            onClick={handleClickButton}
            sx={{
              fontSize: 12,
            }}>
            Close Request
          </MenuItem>
          <MenuItem
            role="button"
            disabled={hasActiveDelivery}
            onClick={handleClickDelivery}
            sx={{
              fontSize: 12,
            }}>
            Create Delivery Service only
          </MenuItem>
          <MenuItem
            role="button"
            disabled={!["UNPAID", "PARTIAL"].includes(paymentStatus)}
            onClick={handleClickPaymentReminder}
            sx={{
              fontSize: 12,
            }}>
            Send Payment Reminder
          </MenuItem>
        </MenuList>
      </OptionButton>
    );
  if (status === "cancelled")
    return (
      <OptionButton disabled={isLoading} label="Action to Close">
        <MenuList id="option-button-menu" autoFocusItem>
          <MenuItem
            role="button"
            onClick={handleClickConfirmCancellation}
            sx={{
              fontSize: 12,
            }}>
            Called User to Confirm
          </MenuItem>
          <MenuItem
            role="button"
            onClick={handleClickRebookCancellation}
            sx={{
              fontSize: 12,
            }}>
            Called User to Rebook
          </MenuItem>
          <MenuItem
            role="button"
            onClick={handleChargeRequest}
            disabled={!hasActiveCard || !request.billing?.psId || paymentStatus === "PAID"}
            sx={{
              fontSize: 12,
            }}>
            Charge User
          </MenuItem>
        </MenuList>
      </OptionButton>
    );
  if (status === "discharged") return null;

  return (
    <Button
      text={buttonText[status]}
      type="button"
      className="primary"
      disabled={
        isLoading || (!free && !paymentMethod && chargeRequest && status === "inProgress") || !providers?.length
      }
      onClick={handleClickButton}
    />
  );
};

const getRecords = async request => {
  const symptomsResult = await getSymptoms({
    alpha2: request.country,
    serviceType: request.type,
    serviceSpecialty: request.specialty,
  });

  const symptoms = symptomsResult?.data?.symptoms;
  const newRecords = cloneDeep(request.records);
  const symptomsRecord = newRecords.find(r => r.type === "symptoms");
  const symptomToBeAdded = symptoms?.filter(s => symptomsRecord.data.symptoms.includes(s.title));
  symptomsRecord.data.symptoms = symptomToBeAdded;

  const newDraftRecords = newRecords.filter(r => r.type !== "symptoms");
  newDraftRecords.push(symptomsRecord);
  return newDraftRecords;
};

export const populateNewRequest = async request => {
  const newRequest = cloneDeep(request);

  newRequest._id = undefined;
  newRequest.id = undefined;
  newRequest.cancelled = {};
  newRequest.providers = null;
  newRequest.isDirty = {};
  newRequest.billing.payments = [];
  newRequest.billing.code = newRequest.billing?.code || {};
  newRequest.isSendBookingConfirmation = true;
  newRequest.bookingConfirmationMethod = "SMS";
  newRequest.bookingConfirmation = undefined;

  const interval = getInterval(newRequest.type);
  const startTime = roundToNearestMinutes(addMinutes(new Date(), Math.ceil(interval / 2)), {
    nearestTo: getInterval(interval),
  });

  newRequest.schedule.start = startTime;
  newRequest.schedule.end = getDefaultEndTime(newRequest.specialty, newRequest.type, startTime);
  newRequest.records = await getRecords(request);
  newRequest.providers = undefined;
  newRequest.business = newRequest.business ?? undefined;
  if (newRequest.type === "Delivery" && newRequest.specialty === "Ambulance") {
    newRequest.schedule.requestedAddress = {};
    newRequest.schedule.destinationAddress = {};
  }
  if (newRequest.type === "Clinic") newRequest.placeRef = newRequest.place.ref;

  return newRequest;
};
