import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Button, ClickAwayListener, Grow, Paper, Popper, Stack } from "@mui/material";
import { useCallback, useMemo, useRef, useState } from "react";

export default function OptionButton({ children, disabled, label, isUnstyled, margin }) {
  const anchorRef = useRef();
  const [showCloseDropDown, setShowCloseDropDown] = useState(false);

  const handleClose = useCallback(event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setShowCloseDropDown(false);
  }, []);

  const toggleCloseDropDown = useCallback(() => {
    setShowCloseDropDown(x => !x);
  }, []);

  const getIconStyles = useMemo(
    () => ({
      transform: showCloseDropDown ? "rotate(180deg)" : "rotate(0deg)",
      transition: "transform 0.1s ease-in-out",
    }),
    [showCloseDropDown],
  );

  return (
    <>
      {isUnstyled && (
        <Stack direction="row" alignItems="center" sx={{ cursor: "pointer" }} onClick={toggleCloseDropDown}>
          <h2 ref={anchorRef}>{label}</h2>
          <KeyboardArrowDown
            sx={{
              ...getIconStyles,
              color: "rgba(0, 0, 0, 0.54)",
            }}
          />
        </Stack>
      )}
      {!isUnstyled && (
        <Button
          ref={anchorRef}
          variant="contained"
          color="secondary"
          size="small"
          disabled={disabled}
          endIcon={<KeyboardArrowDown sx={getIconStyles} />}
          onClick={toggleCloseDropDown}
          data-testid="option-button"
          sx={{
            height: "34px",
            minHeight: "unset",
          }}>
          {label ?? "Actions"}
        </Button>
      )}
      <Popper
        open={showCloseDropDown}
        anchorEl={anchorRef.current}
        role="document"
        placement="bottom-end"
        transition
        disablePortal>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              margin: margin ?? "5px 0",
              borderRadius: 5,
              boxShadow: "0px 2px 8px 0px #00000040",
            }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>{children}</ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
